import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

export const TablePaginationLabelRowsPerPage = () => {
	const theme = useTheme();
	const desktop = useMediaQuery(theme.breakpoints.up('md'));

	return <>{desktop ? 'Na stránku' : ''}</>;
};
