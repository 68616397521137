import React from 'react';

import { createTheme } from '@mui/material/styles';

import { TablePaginationLabelRowsPerPage } from '../atoms/table-pagination-label-rows-per-page';
import { palette } from './palette';
import { getTypography } from './typography';

const getTheme = () => {
	const baseTheme = createTheme({ palette });

	const theme = createTheme({
		palette,
		typography: getTypography(baseTheme),
		components: {
			MuiCircularProgress: {
				styleOverrides: {
					root: {
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto',
					},
				},
			},

			/**
			 * MuiButtonBase
			 */
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true,
				},
			},

			/**
			 * MuiPaper
			 */
			MuiPaper: {
				styleOverrides: {
					root: {
						padding: 16,
						borderRadius: 8,
					},
					elevation1: {
						boxShadow: '0px 2px 48px rgba(0, 0, 0, 0.04)',
					},
				},
			},

			/**
			 * MuiSwitch
			 */
			MuiSwitch: {
				styleOverrides: {
					root: {
						width: 40,
						height: 20,
						padding: 0,
					},
					switchBase: {
						'padding': 0,
						'margin': 2,
						'transitionDuration': '300ms',
						'&.Mui-checked': {
							'transform': 'translateX(20px)',
							'color': '#fff',
							'& + .MuiSwitch-track': {
								backgroundColor: baseTheme.palette.primary.main,
								opacity: 1,
								border: 0,
							},
							'&.Mui-disabled + .MuiSwitch-track': {
								opacity: 0.5,
							},
						},
						'&.Mui-focusVisible .MuiSwitch-thumb': {
							color: '#33cf4d',
							border: '6px solid #fff',
						},
						'&.Mui-disabled .MuiSwitch-thumb': {
							color:
								baseTheme.palette.mode === 'light'
									? baseTheme.palette.grey[100]
									: baseTheme.palette.grey[600],
						},
						'&.Mui-disabled + .MuiSwitch-track': {
							opacity: baseTheme.palette.mode === 'light' ? 0.7 : 0.3,
						},
					},
					thumb: {
						boxSizing: 'border-box',
						width: 16,
						height: 16,
						boxShadow: 'none',
					},
					track: {
						borderRadius: 26 / 2,
						backgroundColor: baseTheme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
						opacity: 1,
						transition: baseTheme.transitions.create(['background-color'], {
							duration: 500,
						}),
					},
				},
			},

			/**
			 * MuiAlert
			 */
			MuiAlert: {
				defaultProps: {
					icon: false,
				},
			},

			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						color: baseTheme.palette.primary.main,
					},
				},
			},

			MuiDrawer: {
				styleOverrides: {
					paper: {
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					},
				},
			},

			MuiTablePagination: {
				defaultProps: {
					labelRowsPerPage: <TablePaginationLabelRowsPerPage />,
					labelDisplayedRows: ({ from, to, count }) => `${from}-${to} z ${count}`,
					rowsPerPageOptions: [25, 50, 100],
				},
			},
		},
	});

	return theme;
};

export const theme = getTheme();
