import React, { lazy } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { Loadable } from './atoms/loader';
import { AuthLayout } from './layouts/auth-layout';
import { MainLayout } from './layouts/main-layout';
import { RequireAuth } from './supabase';

const Home = Loadable(lazy(() => import('./pages/home')));
const Dashboard = Loadable(lazy(() => import('./pages/dashboard')));
const Device = Loadable(lazy(() => import('./pages/device')));
const DeviceUpdateList = Loadable(lazy(() => import('./pages/device-update-list')));
const DeviceUpdateDetail = Loadable(lazy(() => import('./pages/device-update-detail')));
const User = Loadable(lazy(() => import('./pages/user')));
const Users = Loadable(lazy(() => import('./pages/users')));
const Group = Loadable(lazy(() => import('./pages/group')));
const Groups = Loadable(lazy(() => import('./pages/groups')));
const Auth = Loadable(lazy(() => import('./pages/auth')));
const AuthSetPassword = Loadable(lazy(() => import('./pages/auth-set-password')));
const AuthResetPassword = Loadable(lazy(() => import('./pages/auth-reset-password')));
const Me = Loadable(lazy(() => import('./pages/me')));
const PartnerPortal = Loadable(lazy(() => import('./pages/partner-portal')));
const ClientPortal = Loadable(lazy(() => import('./pages/client-portal')));
const MqttCloud = Loadable(lazy(() => import('./pages/mqtt-cloud')));

const routes: RouteObject[] = [
	{
		path: '',
		element: <MainLayout />,
		children: [
			{
				path: '',
				element: (
					<RequireAuth>
						<Home />
					</RequireAuth>
				),
			},
			{
				path: 'dashboard',
				element: (
					<RequireAuth>
						<Dashboard />
					</RequireAuth>
				),
			},
			{
				path: 'devices/:deviceId',
				element: (
					<RequireAuth>
						<Device />
					</RequireAuth>
				),
			},
			{
				path: 'devices/updates',
				element: (
					<RequireAuth>
						<DeviceUpdateList />
					</RequireAuth>
				),
			},
			{
				path: 'devices/updates/:taskId',
				element: (
					<RequireAuth>
						<DeviceUpdateDetail />
					</RequireAuth>
				),
			},
			{
				path: 'users/:userId',
				element: (
					<RequireAuth>
						<User />
					</RequireAuth>
				),
			},
			{
				path: 'users',
				element: (
					<RequireAuth>
						<Users />
					</RequireAuth>
				),
			},
			{
				path: 'groups/:groupId',
				element: (
					<RequireAuth>
						<Group />
					</RequireAuth>
				),
			},
			{
				path: 'groups',
				element: (
					<RequireAuth>
						<Groups />
					</RequireAuth>
				),
			},
			{
				path: 'me',
				element: (
					<RequireAuth>
						<Me />
					</RequireAuth>
				),
			},
			{
				path: 'partner',
				element: (
					<RequireAuth>
						<PartnerPortal />
					</RequireAuth>
				),
			},
			{
				path: 'client',
				element: (
					<RequireAuth>
						<ClientPortal />
					</RequireAuth>
				),
			},
			{
				path: 'mqtt-cloud',
				element: (
					<RequireAuth>
						<MqttCloud />
					</RequireAuth>
				),
			},
		],
	},
	{
		path: 'auth',
		element: <AuthLayout />,
		children: [
			{
				path: '',
				element: <Auth />,
			},
			{
				path: 'set-password',
				element: <AuthSetPassword />,
			},
			{
				path: 'reset-password',
				element: <AuthResetPassword />,
			},
		],
	},
	{
		path: '/*',
		element: <Navigate to="/" replace />,
	},
];

export const Router = () => useRoutes(routes);
